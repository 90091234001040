import React from 'react'
import Layout from '@compositions/Layout'

const breadcrumbs = [{ key: 'blog', text: 'Blog', url: '/blog' }]

const BlogPage = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <h1>Blog Page</h1>
    <p>Lorem ipsum dolor sit amet consectetum...</p>
  </Layout>
)

export default BlogPage
